<script setup lang="ts">
	import { type Ref, ref } from 'vue';
	import { required, minLength } from '@vuelidate/validators';
	import { useVuelidate } from '@vuelidate/core';
	import { useI18n } from 'vue-i18n';
	import MzWrapper from '@/components/MzWrapper.vue';
	import { MzButton, MzInput, MzPhone, MzSelect } from '@monizze/monizze-components';
	import { useUserStore } from '@/stores/user.ts';
	import MzCard from '@/components/MzCard.vue';
	import UserSidebar from '@/components/UserSidebar.vue';
	import { useAuthStore } from '@/stores/auth.ts';
	import { Api } from '@/utils/api.ts';
	import { useRouter } from 'vue-router';
	import PhoneConfirm from '@/components/Mfa/PhoneConfirm.vue';
	import EmailConfirm from '@/components/Mfa/EmailConfirm.vue';

	const { t } = useI18n();
	const userStore = useUserStore();
	const authStore = useAuthStore();

	const editMode: Ref<boolean> = ref(false);
	const editLogin: Ref<boolean> = ref(false);
	const country: string = authStore.isLux ? 'LUX' : 'BE';
	const router = useRouter();
	const displayedModal: Ref<string> = ref('');
	const validationCode: Ref<string> = ref('');
	const validationError: Ref<boolean> = ref(false);

	const form = ref({
		firstname: '',
		lastname: '',
		birthdate: '',
		language: '',
	});
	const emailForm: Ref<string> = ref('');
	const phoneForm: Ref<string> = ref('');
	const emailError: Ref<boolean> = ref(false);
	const phoneError: Ref<boolean> = ref(false);

	const rules = {
		firstname: { required, minLength: minLength(2) },
		lastname: { required, minLength: minLength(3) },
		birthdate: { required },
		language: { required },
	};

	const $v = useVuelidate(rules, form);

	/** @ts-ignore-next-line */
	const handleSubmit = async () => {
		$v.value.$touch();
		if (!$v.value.$invalid) {
			await Api.post('/user', form.value);
			userStore.user.firstname = form.value.firstname;
			userStore.user.lastname = form.value.lastname;
			userStore.user.birthdate = form.value.birthdate;
			userStore.user.language = form.value.language;
			editMode.value = false; // close the form when save is successful
		}
	};

	const handleLoginSubmit = async (type: string) => {
		if (userStore.user.email === emailForm.value && userStore.user.phone === phoneForm.value) {
			return;
		}

		emailError.value = false;
		phoneError.value = false;

		type Body = {
			email?: string;
			phone?: string;
		};

		let body: Body = {};
		if (userStore.user.email !== emailForm.value) {
			body.email = emailForm.value;
		}
		if (userStore.user.phone !== phoneForm.value) {
			body.phone = phoneForm.value;
		}

		try {
			await Api.post('/user/login', body);
		} catch (e) {
			if (type === 'phone') {
				phoneError.value = true;
			} else {
				emailError.value = true;
			}
			return;
		}

		if (type === 'phone') {
			displayedModal.value = 'phone';
		} else {
			displayedModal.value = 'email';
		}
	};

	const handleConfirmLogin = async () => {
		try {
			await Api.post('/user/login/confirm', { code: validationCode.value });
			await userStore.refreshUser();

			validationCode.value = '';
			validationError.value = false;

			displayedModal.value = '';
			editLogin.value = false;
		} catch (e) {
			validationError.value = true;
		}
	};

	const discardChanges = () => {
		form.value = {
			firstname: userStore.user.firstname || '',
			lastname: userStore.user.lastname || '',
			birthdate: userStore.user.birthdate || '',
			language: userStore.user.language,
		};
		editMode.value = false;
	};

	const startEdit = async () => {
		form.value = {
			firstname: userStore.user.firstname || '',
			lastname: userStore.user.lastname || '',
			birthdate: userStore.user.birthdate ? new Date(userStore.user.birthdate).toISOString().split('T')[0] : '',
			language: userStore.user.language,
		};
		editMode.value = true;
	};
	const editProfile = async () => {
		if (!(await userStore.isMfa())) {
			await router.push('/mfa');
			return;
		}
		await userStore.refreshMfa();
		if (userStore.mfaMethods.length === 0) {
			// BR force MFA setup flow if not set
			await router.push({ name: 'user-security', query: { mfa: 'true' } });
			return;
		}

		// Need card also indicates that user hasn't setup MFA yet. Therefore, we launch the MFA flow.
		if (userStore.currentMfaMethod === 'card') {
			displayedModal.value = 'phone';
		}

		emailForm.value = userStore.user.email;
		phoneForm.value = userStore.user.phone;
		editLogin.value = true;
	};
</script>

<template>
	<user-sidebar />
	<phone-confirm
		v-if="displayedModal === 'phone'"
		v-model="validationCode"
		:phone="phoneForm"
		:error="validationError"
		:allow-edit="true"
		@close="displayedModal = ''"
		@edit="displayedModal = 'phone'"
		@submit="handleConfirmLogin()"
	/>
	<email-confirm
		v-if="displayedModal === 'email'"
		v-model="validationCode"
		:email="emailForm"
		:error="validationError"
		:allow-edit="true"
		@close="displayedModal = ''"
		@edit="displayedModal = 'email'"
		@submit="handleConfirmLogin()"
	/>
	<mz-wrapper :title="t('user.profile.title')" :intro="t('user.profile.subtitle')">
		<mz-card v-if="!editLogin" :title="t('user.profile.personal.title')">
			<template v-if="!editMode" #content>
				<div class="info-row">
					<p>{{ t('user.profile.personal.name') }}</p>
					<p>{{ userStore.user.firstname }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.lastname') }}</p>
					<p>{{ userStore.user.lastname }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.birthdate') }}</p>
					<p>{{ userStore.user.birthdate ? new Date(userStore.user.birthdate).toLocaleDateString() : '' }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.language') }}</p>
					<p>{{ userStore.user.language }}</p>
				</div>

				<mz-button type="tertiary" :label="t('user.profile.personal.edit')" :width-auto="true" class="mt-6" @click="startEdit" />
			</template>
			<template v-else #content>
				<form class="flex flex-col gap-6">
					<mz-input
						v-model="form.firstname"
						:label="t('user.profile.personal.name')"
						:validated="!$v.firstname.$invalid"
						required
						minlength="2"
					/>
					<mz-input
						v-model="form.lastname"
						:label="t('user.profile.personal.lastname')"
						:validated="!$v.lastname.$invalid"
						required
						minlength="3"
					/>
					<mz-input
						v-if="!userStore.user.birthdate"
						v-model="form.birthdate"
						type="date"
						required
						:label="t('user.profile.personal.birthdate')"
					/>
					<mz-select
						v-model="form.language"
						:label="t('user.profile.personal.language')"
						:validated="!$v.language.$invalid"
						:options="authStore.availableLanguages.map((lang) => ({ label: t(`language.${lang}`), value: lang }))"
					></mz-select>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button type="primary" :label="t('user.profile.personal.save')" :width-auto="true" @click.prevent="handleSubmit" />
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
		<mz-card v-if="!editLogin" class="mt-9" :title="t('user.profile.contact.title')">
			<template #content>
				<div class="info-row">
					<p>{{ t('user.profile.contact.email') }}</p>
					<p>{{ userStore.user.email }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.contact.phone') }}</p>
					<p>{{ userStore.user.phone }}</p>
				</div>
				<mz-button type="tertiary" :label="t('user.profile.contact.edit')" :width-auto="true" class="mt-6" @click="editProfile" />
			</template>
		</mz-card>
		<mz-card v-if="editLogin" :title="t('user.profile.contact.email')">
			<template #content>
				<form class="flex flex-col gap-6">
					<mz-input
						v-model="emailForm"
						:label="t('user.profile.contact.email')"
						required
						minlength="3"
						type="email"
						:validated="emailError ? false : undefined"
						:info="emailError ? t('user.profile.contact.invalid.email') : ''"
					/>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button
							type="primary"
							:label="t('user.profile.personal.save')"
							:width-auto="true"
							@click.prevent="handleLoginSubmit('email')"
						/>
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>

		<mz-card v-if="editLogin" :title="t('login.form.phone')">
			<template #content>
				<form class="flex flex-col gap-6">
					<mz-phone
						id="phone"
						v-model="phoneForm"
						type="tel"
						:tab-index="1"
						:label="t('login.form.phone')"
						:country="country"
						:validated="phoneError ? false : undefined"
						:info="phoneError ? t('user.profile.contact.invalid.phone') : ''"
						autocomplete="phone"
						href="forgot-username"
						required
					/>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button
							type="primary"
							:label="t('user.profile.personal.save')"
							:width-auto="true"
							@click.prevent="handleLoginSubmit('phone')"
						/>
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
	</mz-wrapper>
</template>

<style lang="sass" scoped>
	.info-row
		@apply flex w-full flex-wrap border-b border-global-border py-4 gap-4 sm:gap-0
		p:first-of-type
			@apply text-label-medium
		p:last-of-type
			@apply text-info-large
		p
			@apply w-full sm:w-1/2

	.info-row:last-of-type
		@apply border-none
</style>
