<script setup lang="ts">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { defineProps } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { LatestDeviceInfoDetail } from '@/types/Responses/LatestDeviceInfo.ts';

	const { t } = useI18n();

	defineProps<{
		device: LatestDeviceInfoDetail;
	}>();
</script>

<template>
	<div class="flex justify-space-between gap-4 py-8 cursor-pointer" @click="() => $router.push({ name: 'user-security-history' })">
		<div class="text-center w-[24px] flex-none">
			<span><font-awesome-icon :icon="['fas', device.type]" class="icon-24px" /></span>
		</div>
		<div class="pl-4 flex-grow">
			<p v-if="device.device" class="text-global-h6 font-semibold">{{ device.device }}</p>
			<p v-else class="text-global-h6 font-semibold">{{ t('user.security.device.unknown') }}</p>
			<p class="text-info-large">{{ t('user.security.device.sessions', { count: device.session }) }}</p>
		</div>
		<button class="text-right flex-none">
			<span><font-awesome-icon :icon="['fas', 'fa-chevron-right']" class="icon-24px" /></span>
		</button>
	</div>
</template>

<style scoped>
	.icon-24px {
		font-size: 24px;
	}
</style>
