<script setup lang="ts">
	import { onBeforeMount, Ref, ref } from 'vue';
	import UserSidebar from '@/components/UserSidebar.vue';
	import { MzTitle, MzLoader } from '@monizze/monizze-components';
	import MzCard from '@/components/MzCard.vue';
	import DetailedDeviceInfo from '@/components/Device/DetailedDeviceInfo.vue';
	import { useDevicesStore } from '@/stores/devices.ts';
	import { useI18n } from 'vue-i18n';
	import { DeviceDetailedInfo } from '@/types/Responses/DeviceDetailedInfo.ts';
	import { LoginDevice } from '@/types/Responses/LoginDevice.ts';
	import SessionDetail from '@/components/Device/SessionDetail.vue';
	import LogoutModal from '@/components/Device/LogoutModal.vue';

	const { t } = useI18n();

	const devicesStore = useDevicesStore();

	const devices: Ref<null | DeviceDetailedInfo[]> = ref(null);
	const selectedSession: Ref<null | { device: DeviceDetailedInfo; session: LoginDevice }> = ref(null);
	const selectedLogoutDevice: Ref<null | DeviceDetailedInfo> = ref(null);

	onBeforeMount(async () => {
		devices.value = await devicesStore.fetchDevices();
	});

	const showDeviceDetail = (device: DeviceDetailedInfo, session: LoginDevice) => {
		selectedSession.value = { device, session };
	};
	const showLogoutModal = (device: null | DeviceDetailedInfo) => {
		selectedLogoutDevice.value = device;
	};
	const reloadAfterLogout = async () => {
		devices.value = await devicesStore.fetchDevices();
		selectedSession.value = null;
	};
</script>

<template>
	<user-sidebar />
	<div class="pt-[8rem]">
		<div class="mx-auto max-w-[1400px]">
			<div class="flex justify-center">
				<section class="sm:pl-[260px] m-9 text-base w-full">
					<template v-if="selectedSession === null">
						<div>
							<mz-title :text="t('user.security.device.history.title')" />
							<mz-title :text="t('user.security.device.history.subtitle')" type="h6" />
						</div>
						<mz-card>
							<template #content>
								<div v-if="devices === null" class="h-60">
									<mz-loader />
								</div>
								<template v-else>
									<div v-for="(device, key) in devices" :key="key" class="mx-9">
										<hr v-if="key !== 0" class="my-4 lg:my-8" />
										<detailed-device-info
											:device="device"
											@show-session="(session) => showDeviceDetail(device, session)"
											@logout="() => showLogoutModal(device)"
										/>
									</div>
								</template>
							</template>
						</mz-card>
					</template>
					<session-detail v-else :session="selectedSession.session" @logout="() => showLogoutModal(selectedSession?.device || null)" />
				</section>
			</div>
		</div>
	</div>
	<LogoutModal
		v-if="selectedLogoutDevice !== null"
		:show="true"
		:device="selectedLogoutDevice"
		@close="selectedLogoutDevice = null"
		@reload="reloadAfterLogout"
	></LogoutModal>
</template>

<style scoped></style>
