import { createApp } from 'vue';
import 'tailwindcss/tailwind.css';
import '@monizze/monizze-components/dist/style.css';
import '@/style.sass';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import en from '@/lang/en.json';
import fr from '@/lang/fr.json';
import nl from '@/lang/nl.json';
import fr_LU from '@/lang/fr_LU.json';
import en_LU from '@/lang/en_LU.json';
import de_LU from '@/lang/de_LU.json';
import router from './router';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { useFavicon } from '@vueuse/core';
import { useAuthStore } from '@/stores/auth.ts';
import { install } from 'vue3-recaptcha-v2';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faSpinnerThird,
	faHome,
	faUser,
	faShield,
	faBell,
	faKey,
	faTrash,
	faMessageText,
	faEnvelope,
	faXmark,
	faArrowLeft,
	faLaptop,
	faTabletScreenButton,
	faMobileScreenButton,
	faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

export const languages = {
	en: en,
	en_LU: en_LU,
	fr: fr,
	fr_LU: fr_LU,
	nl: nl,
	de_LU: de_LU,
};

library.add(faSpinnerThird);
library.add(faHome);
library.add(faUser);
library.add(faShield);
library.add(faBell);
library.add(faKey);
library.add(faTrash);
library.add(faMessageText);
library.add(faEnvelope);
library.add(faXmark);
library.add(faArrowLeft);
library.add(faLaptop);
library.add(faTabletScreenButton);
library.add(faMobileScreenButton);
library.add(faChevronRight);

const app = createApp(App);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.use(router);

const pinia = createPinia();
app.use(pinia);

app.use(install, {
	sitekey: import.meta.env.VITE_CAPTCHA_SITEKEY,
	cnDomains: false,
});

// Pre-detect locale
const isLux: boolean = useAuthStore().isLux;
const userLocale: string = (navigator.language || '').split('-')[0];
export const detectedLanguage: string = isLux
	? ['fr', 'en'].includes(userLocale)
		? userLocale
		: 'fr'
	: ['en', 'fr', 'nl'].includes(userLocale)
		? userLocale
		: 'en';

useFavicon(isLux ? '/favicon-lux.png' : '/favicon-monizze.png');

const i18n = createI18n({
	legacy: false,
	locale: isLux ? detectedLanguage + '_LU' : detectedLanguage,
	fallbackLocale: isLux ? 'fr' : 'en',
	messages: languages,
});
app.use(i18n);

Sentry.init({
	app,
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/account-api\.dev\.monizze\.be/,
		/^https:\/\/account-api\.staging\.monizze\.be/,
		/^https:\/\/account-api\.monizze\.be/,
	],
	// Session Replay
	replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app');
