import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth.ts';
import { client } from 'laravel-precognition-vue';
import Profile from '@/pages/User/Profile.vue';
import Dashboard from '@/pages/User/Dashboard.vue';
import Security from '@/pages/User/Security.vue';
import { useStorage } from '@vueuse/core';
import { useUserStore } from '@/stores/user.ts';
import Access from '@/pages/User/Access.vue';
import MfaValidation from '@/pages/Mfa/MfaValidation.vue';
import PasswordEdit from '@/pages/User/PasswordEdit.vue';
import History from '@/pages/Device/History.vue';
const Login = () => import('@/pages/Login/Login.vue');
const LoginEmail = () => import('@/pages/Login/LoginEmail.vue');
const LoginPhone = () => import('@/pages/Login/LoginPhone.vue');
const Approve = () => import('@/pages/Approve.vue');
const Partner = () => import('@/pages/Partner.vue');
const ForgotPassword = () => import('@/pages/ForgotPassword/ForgotPassword.vue');
const ForgotPasswordEmail = () => import('@/pages/ForgotPassword/ForgotPasswordEmail.vue');
const ForgotPasswordPhone = () => import('@/pages/ForgotPassword/ForgotPasswordPhone.vue');
const ForgotPasswordSent = () => import('@/pages/ForgotPasswordSent.vue');
const ResetPassword = () => import('@/pages/ResetPassword.vue');
const ResetPasswordConfirmed = () => import('@/pages/ResetPasswordConfirmed.vue');
const ForgotUsername = () => import('@/pages/ForgotUsername.vue');
const ForgotUsernameResponse = () => import('@/pages/ForgotUsernameResponse.vue');
const Duplicated = () => import('@/pages/Duplicated.vue');
const Duplicated2FA = () => import('@/pages/Duplicated2FA.vue');
const FuzzionSuccess = () => import('@/pages/FuzzionSuccess.vue');
const FuzzionError = () => import('@/pages/FuzzionError.vue');
const FuzzionRejection = () => import('@/pages/FuzzionRejection.vue');

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: '/user/dashboard',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			children: [
				{
					path: 'email',
					name: 'login.email',
					component: LoginEmail,
				},
				{
					path: 'phone',
					name: 'login.phone',
					component: LoginPhone,
				},
			],
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
			children: [
				{
					path: 'email',
					name: 'forgot-password.email',
					component: ForgotPasswordEmail,
				},
				{
					path: 'phone',
					name: 'forgot-password.phone',
					component: ForgotPasswordPhone,
				},
			],
		},
		{
			path: '/forgot-password-sent',
			name: 'forgot-password-sent',
			component: ForgotPasswordSent,
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: ResetPassword,
		},
		{
			path: '/reset-password-confirmed',
			name: 'reset-password-confirmed',
			component: ResetPasswordConfirmed,
		},
		{
			path: '/forgot-username',
			name: 'forgot-username',
			component: ForgotUsername,
		},
		{
			path: '/forgot-username-response',
			name: 'forgot-username-response',
			component: ForgotUsernameResponse,
		},
		{
			path: '/partner',
			name: 'partner',
			component: Partner,
			props: true,
		},
		{
			path: '/approve',
			name: 'approve',
			component: Approve,
		},
		{
			path: '/duplicated',
			name: 'duplicated',
			component: Duplicated,
		},
		{
			path: '/duplicated-2fa',
			name: 'duplicated-2fa',
			component: Duplicated2FA,
		},
		{
			path: '/fuzzion-success',
			name: 'fuzzion-success',
			component: FuzzionSuccess,
		},
		{
			path: '/fuzzion-error',
			name: 'fuzzion-error',
			component: FuzzionError,
		},
		{
			path: '/fuzzion-rejection',
			name: 'fuzzion-rejection',
			component: FuzzionRejection,
		},
		{
			path: '/user/dashboard',
			name: 'user-dashboard',
			component: Dashboard,
		},
		{
			path: '/user/profile',
			name: 'user-profile',
			component: Profile,
		},
		{
			path: '/user/password',
			name: 'user-password',
			component: PasswordEdit,
		},
		{
			path: '/user/security',
			name: 'user-security',
			component: Security,
		},
		{
			path: '/user/security/history',
			name: 'user-security-history',
			component: History,
		},
		{
			path: '/user/access',
			name: 'user-access',
			component: Access,
		},
		{
			path: '/mfa',
			name: 'mfa',
			component: MfaValidation,
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'NotFound',
			redirect: { name: 'home' },
		},
	],
});

router.beforeEach(async (to) => {
	const authStore = useAuthStore();

	authStore.token !== '' ? (client.axios().defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token) : null;

	const needGuest: boolean = [
		'login.email',
		'login.phone',
		'forgot-password.email',
		'forgot-password.phone',
		'forgot-password-sent',
		'reset-password',
		'reset-password-confirmed',
		'forgot-username',
		'forgot-username-response',
	].includes(to?.name?.toString() || '');

	const isUnprotected: boolean = ['mfa'].includes(to?.name?.toString() || '');

	if (to?.name?.toString() === 'login') {
		return { name: 'login.email' };
	}

	if (authStore.isLogged) {
		useStorage('account', '{}').value = JSON.stringify(useUserStore().user);
	}

	if (!isUnprotected) {
		if (authStore.isLogged && needGuest) {
			return { name: 'home' };
		}

		if (!authStore.isLogged && !authStore.isDuplicatedLogged && !needGuest) {
			authStore.setTargetUrl(to);
			return { name: 'login.email' };
		}
	}
});

export default router;
