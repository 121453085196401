import { defineStore } from 'pinia';
import { LoginResponse } from '@/types/Responses/LoginResponse.ts';
import { Ref, ref } from 'vue';
import { OAuthClientResponse } from '@/types/Responses/OAuthClientResponse.ts';
import { LocationQueryValue, RouteLocationNormalized, useRouter } from 'vue-router';
import type { OAuthApproveResponse } from '@/types/Responses/OAuthApproveResponse.ts';
import { RedirectResponse } from '@/types/Responses/RedirectResponse.ts';
import { ForgotPasswordResponse } from '@/types/Responses/ForgotPasswordResponse.ts';
import { ForgotUsernameResponse } from '@/types/Responses/ForgotUsernameResponse.ts';
import Cookies from 'js-cookie';
import { useStorage } from '@vueuse/core';
import { useUserStore } from '@/stores/user.ts';
import { Api } from '@/utils/api.ts';
import { LoginNeedMfaResponse } from '@/types/Responses/LoginNeedMfaResponse.ts';

export const useAuthStore = defineStore('auth', () => {
	// Logged user
	const cookiePrefix = ['develop', 'staging'].includes(import.meta.env.VITE_ENV ?? '') ? `${import.meta.env.VITE_ENV}_` : '';
	const token: Ref<string> = ref(Cookies.get(`${cookiePrefix}uzzer_token`) || Cookies.get(`${cookiePrefix}uzzer_token_duplicated`) || '');
	const isLogged: Ref<boolean> = ref(token.value.length > 0);
	const isJustLogged: Ref<boolean> = ref(false); // Difference with isLogged is wether we were logged during this session or not
	const isDuplicatedLogged: Ref<boolean> = ref(Cookies.get('uzzer_token_duplicated') !== undefined);
	const isLux: Ref<boolean> = ref(window.location.host.includes('up-luxembourg'));
	const availableLanguages: string[] = isLux.value ? ['fr_LU', 'en_LU'] : ['fr', 'nl', 'en'];
	const defaultLocale = isLux.value ? 'fr_LU' : 'nl';
	const loginInputType: Ref<string> = ref('');
	const userDuplicatedInfo = ref({
		email: '',
		phone: '',
		email_duplicated: false,
		phone_duplicated: false,
		has_mymonizze: false,
		has_clientarea: false,
		duplicated_mymonizze: 0,
		duplicated_clientarea: 0,
	});
	//For redirect on consent
	const redirectUri: Ref<string> = ref('');
	const oauthState: Ref<string> = ref('');

	// For Approval screen with OAuth scope acceptation
	const partnerData: Ref<any> = ref({});
	const targetUrl: Ref<string> = ref('/');

	//Forgot Password
	const forgotPasswordSent: Ref<boolean> = ref(false);
	const forgotPasswordDestination: Ref<string> = ref('');
	const forgotPasswordDestinationType: Ref<string> = ref('');
	const resetPasswordCode: Ref<number | null> = ref(null);

	// Forgot username
	const forgotUsernameInput: Ref<string> = ref('');
	const forgotUsernameInputType: Ref<string> = ref('');
	const forgotUsernameEmail: Ref<string | null> = ref(null);
	const forgotUsernamePhone: Ref<string | null> = ref(null);

	const router = useRouter();

	const getToken = async (requestBody: BodyInit): Promise<any> => {
		const data = {
			method: 'POST',
			headers: Api.commonHeaders,
			body: requestBody,
		};
		const url = Api.commonUrl + '/token';
		return new Promise((resolve, reject) => {
			fetch(url, data)
				.then(async (response) => {
					if (response.status === 401) {
						const payload = (await response.json()) as LoginNeedMfaResponse;
						await router.push({ name: 'mfa', query: { method: payload.mfa.method, payload: payload.mfa.payload } });
						throw 'Need MFA';
					}
					if (response.status !== 200) {
						throw response.status;
					} else {
						return response.json();
					}
				})
				.then((result: LoginResponse) => {
					loginByToken(result.access_token, result.duplicated).then((result) => {
						resolve(result);
					});
				})
				.catch((response) => {
					reject(response);
				});
		});
	};

	const loginByToken = (access_token: string, duplicated: boolean): Promise<unknown> => {
		return new Promise((resolve) => {
			isJustLogged.value = true;

			isLogged.value = true;

			let cookieDomain: string | undefined = '';
			if (window.location.host.includes('localhost')) {
				cookieDomain = undefined;
			} else {
				cookieDomain = window.location.host.replace('account.', '');
			}
			token.value = access_token;

			// Fetch user
			useUserStore().refreshUser();

			if (duplicated) {
				Cookies.set(`${cookiePrefix}uzzer_token_duplicated`, access_token, {
					expires: 30,
					sameSite: 'Lax',
					domain: cookieDomain,
				});
				isDuplicatedLogged.value = true;
				resolve({ duplicated: true });
			} else {
				// NOTE: sameSite is required to avoid trigger a warning with FF
				Cookies.set(`${cookiePrefix}uzzer_token`, access_token, {
					expires: 30,
					sameSite: 'Lax',
					domain: cookieDomain,
				});
				isLogged.value = true;
				resolve({ duplicated: false });
			}
		});
	};

	const logout = (): void => {
		let cookieDomain: string | undefined = '';
		if (window.location.host.includes('localhost')) {
			cookieDomain = undefined;
		} else {
			cookieDomain = window.location.host.replace('account.', '');
		}
		Cookies.remove(`${cookiePrefix}uzzer_token`, { domain: cookieDomain });
		Cookies.remove(`${cookiePrefix}uzzer_token_duplicated`, { domain: cookieDomain });
		Cookies.remove(`${cookiePrefix}token`, { domain: cookieDomain });
		token.value = '';
		isLogged.value = false;
		isJustLogged.value = false;
		isDuplicatedLogged.value = false;
		useStorage('account', {}).value = null;
		useUserStore().clean();
	};

	const getAuthorize = async (
		client_id: string | null | LocationQueryValue[],
		redirect_uri: string | null | LocationQueryValue[],
	): Promise<string> => {
		Api.commonHeaders.Authorization = 'Bearer ' + token.value;
		const data = {
			method: 'GET',
			headers: Api.commonHeaders,
		};
		const url = Api.commonUrl + '/authorize?client_id=' + client_id + '&redirect_uri=' + redirect_uri + '&response_type=code';
		return new Promise<string>((resolve, reject) => {
			fetch(url, data)
				.then(async (response) => {
					if (!response.ok) {
						throw await response.json();
					}
					return response.json() as Promise<OAuthClientResponse | OAuthApproveResponse>;
				})
				.then((result) => {
					if (result.approvalType === 'scopes') {
						partnerData.value = result;
						resolve('approve');
					} else {
						resolve(result.target);
					}
				})
				.catch((error) => reject(error));
		});
	};
	const sendApproval = async (method: string, requestBody: BodyInit) => {
		// can be a POST for approval or DELETE for refusal
		Api.commonHeaders.Authorization = 'Bearer ' + token.value;
		const data = {
			method: method,
			headers: Api.commonHeaders,
			body: requestBody,
			redirect: 'manual',
		};
		const url = Api.commonUrl + '/authorize';
		return fetch(url, data as any).then((response) => {
			if (response.status >= 400) {
				return response.json().then((error) => {
					throw error;
				});
			} else {
				return response.json();
			}
		});
	};

	const fetchDuplicated = async (): Promise<any> => {
		Api.commonHeaders.Authorization = 'Bearer ' + token.value;
		const data = {
			method: 'GET',
			headers: Api.commonHeaders,
		};
		const url = Api.commonUrl + '/duplicated';
		return new Promise<any>((resolve, reject) => {
			fetch(url, data)
				.then((response) => {
					return response.json();
				})
				.then((response) => {
					userDuplicatedInfo.value = response.data;
					resolve(true);
				})
				.catch((error) => reject(error));
		});
	};

	const setTargetUrl = (to: RouteLocationNormalized): void => {
		targetUrl.value = to.fullPath;
	};

	const storeForgotPasswordInfo = (data: ForgotPasswordResponse): void => {
		forgotPasswordSent.value = true;
		forgotPasswordDestination.value = data.recipient[0];
		forgotPasswordDestinationType.value = data.technology == 'SMS' ? 'sms' : 'email';
	};

	const sendForgotPassword = async (requestBody: BodyInit): Promise<boolean> => {
		const data = {
			method: 'POST',
			headers: Api.commonHeaders,
			body: requestBody,
		};
		const url = Api.commonUrl + '/user/forgot-password';
		return new Promise<any>((resolve) => {
			fetch(url, data as any)
				.then((response) => {
					return response.json();
				})
				.then((result: RedirectResponse) => resolve(result));
		});
	};

	const resetPassword = async (requestBody: BodyInit): Promise<boolean> => {
		const data = {
			method: 'POST',
			headers: Api.commonHeaders,
			body: requestBody,
		};
		const url = Api.commonUrl + '/user/reset-password';
		return new Promise((resolve, reject) => {
			fetch(url, data)
				.then((response) => {
					return response.json();
				})
				.then(() => {
					resolve(true);
				})
				.catch((error) => reject(error));
		});
	};

	const storeForgotUsernameInfo = (data: ForgotUsernameResponse | null, pan: string | null): void => {
		forgotUsernameEmail.value = data ? data.email : null;
		forgotUsernamePhone.value = data ? data.phone : null;

		forgotUsernameInput.value = pan ?? '';
		forgotUsernameInputType.value = 'pan';
	};

	const getPlatformComingFrom = (): string => {
		const url = targetUrl.value.split('redirect_uri=')[1] ?? 'Monizze';

		if (url.includes('localhost:8003') || url.includes('my.')) {
			return 'login.page.platform.mm';
		} else if (url.includes('localhost:8004') || url.includes('client.')) {
			return 'login.page.platform.ca';
		} else {
			return 'login.page.platform.default';
		}
	};

	const deleteDuplicated = async () => {
		const requestBody = JSON.stringify({
			email: userDuplicatedInfo.value.email,
			phone: userDuplicatedInfo.value.phone,
			remove_user_linked: userDuplicatedInfo.value.duplicated_mymonizze > 0,
			remove_customer_linked: userDuplicatedInfo.value.duplicated_clientarea > 0,
		});
		Api.commonHeaders.Authorization = 'Bearer ' + token.value;
		const data = {
			method: 'DELETE',
			headers: Api.commonHeaders,
			body: requestBody,
		};
		const url = Api.commonUrl + '/duplicated';

		return fetch(url, data).then((response) => {
			return response.json();
		});
	};

	const skipDuplication = (): void => {
		userDuplicatedInfo.value = {
			email: '',
			phone: '',
			email_duplicated: false,
			phone_duplicated: false,
			has_mymonizze: false,
			has_clientarea: false,
			duplicated_mymonizze: 0,
			duplicated_clientarea: 0,
		};
		isDuplicatedLogged.value = false;
		isLogged.value = true;
	};

	return {
		// DATA
		token,
		loginInputType,
		isLogged,
		isJustLogged,
		isLux,
		availableLanguages,
		defaultLocale,
		isDuplicatedLogged,
		userDuplicatedInfo,
		partnerData,
		targetUrl,
		forgotPasswordSent,
		forgotPasswordDestination,
		forgotPasswordDestinationType,
		resetPasswordCode,
		forgotUsernameEmail,
		forgotUsernamePhone,
		forgotUsernameInput,
		forgotUsernameInputType,
		redirectUri,
		oauthState,

		// FUNCTIONS
		getToken,
		logout,
		getAuthorize,
		sendApproval,
		fetchDuplicated,
		setTargetUrl,
		storeForgotPasswordInfo,
		sendForgotPassword,
		resetPassword,
		storeForgotUsernameInfo,
		getPlatformComingFrom,
		deleteDuplicated,
		skipDuplication,
		loginByToken,
	};
});
