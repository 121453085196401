<script setup lang="ts">
	import { MzButton, MzPhone } from '@monizze/monizze-components';
	import MzModal from '@/components/MzModal.vue';
	import { useI18n } from 'vue-i18n';
	import { defineModel } from 'vue';
	import { useAuthStore } from '@/stores/auth.ts';

	const emit = defineEmits<{
		(e: 'close'): void;
		(e: 'submit'): void;
	}>();
	const phone = defineModel<string>({ required: true });
	const { t } = useI18n();
	const authStore = useAuthStore();
</script>

<template>
	<mz-modal :title="t('user.mfa.setup.phone.title')" @close="emit('close')">
		<template #content>
			<p>{{ t('user.mfa.setup.phone.description') }}</p>
			<mz-phone
				id="phone"
				v-model="phone"
				type="tel"
				:tab-index="1"
				:label="t('login.form.phone')"
				autocomplete="phone"
				:country="authStore.isLux ? 'LU' : 'BE'"
				required
				class="mb-2"
				@keyup.enter="emit('submit')"
			/>
		</template>
		<template #footer>
			<mz-button type="minimal" :label="t('general.cancel')" width-auto @click="emit('close')" />
			<mz-button :label="t('user.mfa.setup.send')" width-auto @click="emit('submit')" />
		</template>
	</mz-modal>
</template>

<style scoped></style>
