<script setup lang="ts">
	import { MzButton, MzInput } from '@monizze/monizze-components';
	import MzModal from '@/components/MzModal.vue';
	import { useI18n } from 'vue-i18n';
	import { defineModel } from 'vue';

	const emit = defineEmits<{
		(e: 'close'): void;
		(e: 'edit'): void;
		(e: 'submit'): void;
	}>();

	const props = defineProps({
		error: {
			type: Boolean,
			default: false,
		},
		email: {
			type: String,
			required: true,
		},
		allowEdit: {
			type: Boolean,
			default: true,
		},
	});

	const validationCode = defineModel<string>({ required: true });
	const { t } = useI18n();
</script>

<template>
	<mz-modal :title="t('user.mfa.setup.email.verify')" @close="emit('close')">
		<template #content>
			<p>
				{{ t('user.mfa.setup.email.message') }}&nbsp;
				<span class="text-body-intro">{{ email }}</span>
			</p>
			<p>{{ t('user.mfa.setup.phone.code') }}</p>
			<mz-input
				id="validationCodeEmail"
				v-model="validationCode"
				:label="t('auth.modal.code')"
				:info="props.error ? t('user.mfa.confirm.code.error') : ''"
				:validated="props.error ? false : undefined"
				required
				minlength="6"
				class="w-full mt-9"
			/>
		</template>
		<template #footer>
			<a v-if="allowEdit" href="#" class="p-1 text-base mt-2 font-bold" @click.prevent="emit('edit')">
				{{ t('user.mfa.setup.email.edit') }}
			</a>
			<mz-button type="minimal" :label="t('general.cancel')" :width-auto="true" class="pe-10" @click="emit('close')" />
			<mz-button :label="t('user.mfa.setup.send')" :width-auto="true" @click="emit('submit')" />
		</template>
	</mz-modal>
</template>

<style scoped></style>
