import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';
import { User } from '@/types/User.ts';
import { Api } from '@/utils/api.ts';
import { MfaMethod } from '@/types/MfaMethod.ts';
import { useI18n } from 'vue-i18n';
import { UserResponse } from '@/types/Responses/UserResponse.ts';
import { MfaStatusResponse } from '@/types/Responses/MfaStatusResponse.ts';
import { MfaRequestSudo } from '@/types/Responses/MfaRequestSudo.ts';
import { useAuthStore } from '@/stores/auth.ts';

export const useUserStore = defineStore('user', () => {
	const user: Ref<User> = ref({
		id: '',
		email: '',
		phone: '',
		fridom_user_id: null,
		user_id: null,
		customer_user_id: null,
		firstname: '',
		lastname: '',
		birthdate: '',
		language: 'en',
		password_status: 0,
		last_password_change: null,
	});
	const mfaMethods: Ref<MfaMethod[]> = ref([]);
	const payload: Ref<string> = ref(''); // Holds the current payload in case of MFA request
	const currentMfaMethod: Ref<string> = ref('');
	const { locale } = useI18n();
	const authStore = useAuthStore();

	const refreshUser = async () => {
		try {
			const response = await Api.get('/user');

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const responseBody = await (response.json() as Promise<UserResponse>);

			user.value = responseBody.data;

			locale.value = authStore.availableLanguages.includes(user.value.language) ? user.value.language : authStore.defaultLocale;

			return responseBody;
		} catch (error) {
			console.error('Fetch Error:', error);
		}
	};

	const refreshMfa = async () => {
		try {
			const response = await Api.get('/mfa');
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			mfaMethods.value = await (response.json() as Promise<MfaMethod[]>);
		} catch (error) {
			console.error('Fetch Error:', error);
		}
	};

	const clean = () => {
		user.value = {
			id: '',
			email: '',
			phone: '',
			fridom_user_id: null,
			user_id: null,
			customer_user_id: null,
			firstname: '',
			lastname: '',
			birthdate: '',
			language: 'en',
			password_status: 0,
			last_password_change: null,
		};
		mfaMethods.value = [];
		payload.value = '';
		currentMfaMethod.value = '';
	};

	const isSudomode = async (): Promise<boolean> => {
		try {
			const response = await Api.get('/mfa/status');
			const data = await (response.json() as Promise<MfaStatusResponse>);

			// This specific tweak is necessary to force MFA flow if not set
			currentMfaMethod.value = data.need_card ? 'card' : '';

			return data.sudo;
		} catch (error) {
			return Promise.reject(false);
		}
	};

	const requestSudomode = async (): Promise<void> => {
		const response = await Api.get('/mfa/sudo');
		const data = await (response.json() as Promise<MfaRequestSudo>);

		await setRequestedSudomode(data.payload, data.method);
	};

	const setRequestedSudomode = async (newPayload: string, newMethod: string): Promise<void> => {
		payload.value = newPayload;
		currentMfaMethod.value = newMethod;
	};

	const activateSudomode = async (code: string, isTrustedDevice: boolean): Promise<boolean> => {
		const response = await Api.post('/mfa/sudo', { payload: payload.value, code, isTrustedDevice: isTrustedDevice });
		const data = await response.json();
		if (data.access_token) {
			await authStore.loginByToken(data.access_token, false);
		}
		return await isSudomode();
	};

	return {
		mfaMethods,
		currentMfaMethod,
		user,
		clean,
		refreshUser,
		refreshMfa,
		isMfa: isSudomode,
		requestMfa: requestSudomode,
		setRequestedMfa: setRequestedSudomode,
		activateMfa: activateSudomode,
	};
});
